import React from "react";
import { MenuItem, Icon} from 'react-foundation';
class AircraftManualTypeMenuComponent extends React.Component {

  render() {
    const manulTypes = this.props.MenuContent;
    //console.log(checkboxList);
    return (
      <>
        {manulTypes.map(option => ( 
          
          <MenuItem><a onClick={() => this.props.onClick(option.ModelCode, option.ManualType)}><Icon name="fi-list"/> {option.ManualType}</a></MenuItem>
          
        ))}
      </>
    );
  }
}
export default AircraftManualTypeMenuComponent;