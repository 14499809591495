import React from "react";
import { MenuItem, Icon} from 'react-foundation';
class AircraftManualNameMenuComponent extends React.Component {

  render() {
    const manualTypes = this.props.MenuContent;
    const link = 'https://tpapi.regional-services.com/Nb5f7UJ32Vfc05r/';
    //console.log(manualTypes);
    return (
      <>
        {manualTypes.map(option => ( 
          
          /* <MenuItem><a onClick={() => this.props.onClick(option.ManualID)}><Icon name="fi-page-pdf large-icon"/> {option.Title}</a></MenuItem> */
          <MenuItem><a href={link+option.ManualID} target="_blank"><Icon name="fi-page-pdf large-icon"/> {option.Title} - {option.RevisionNumber}</a></MenuItem>
          
        ))}
      </>
    );
  }
}
export default AircraftManualNameMenuComponent;