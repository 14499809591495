import React, { useState } from 'react';

const SupplementTable = ({ data }) => {
  const link = 'https://tpapi.regional-services.com/Sf47Yhbv82Dfq0y/';
  const [filterAircraftType, setFilterAircraftType] = useState('');
  const [filterSupplementType, setFilterSupplementType] = useState('');
  const [filterSupplementRef, setFilterSupplementRef] = useState('');

  const handleAircraftTypeChange = (e) => {
    setFilterAircraftType(e.target.value);
  };

  const handleSupplementTypeChange = (e) => {
    setFilterSupplementType(e.target.value);
  };

  const handleSupplementRefChange = (e) => {
    setFilterSupplementRef(e.target.value);
  }

  const filteredData = data.filter(item => 
    (filterAircraftType === '' || item.AircraftType.includes(filterAircraftType.toUpperCase())) &&
    (filterSupplementType === '' || item.TypeOfSupplement.includes(filterSupplementType.toUpperCase())) &&
    (filterSupplementRef === '' || item.SupRef.includes(filterSupplementRef))
  );

  
  return (
    <div>
      <table border="1">
      <thead>
          <tr>
            <th>Action</th>
            <th>Aircraft Type
            <input type="text" value={filterAircraftType} onChange={handleAircraftTypeChange} />
            </th>
            <th>Construction No.</th>
            <th>Supplement Ref.
            <input type="text" value={filterSupplementRef} onChange={handleSupplementRefChange} />
            </th>
            <th>Title</th>
            <th>Supplement Type
            <input type="text" value={filterSupplementType} onChange={handleSupplementTypeChange} />
            </th>
            <th>Revision No.</th>
            <th>Revision Date</th>
          </tr>
        </thead>
        <tbody>
          
          {filteredData.map((item, index) => (
            <tr key={index}>
              <td><a href={link+item.ID} className="btn41-43 btn-41" target="_blank">Download</a></td>
              <td>{item.AircraftType}</td>
              <td>{item.AircraftConstructionNumber}</td>
              <td>{item.SupRef}</td>
              <td>{item.Title}</td>
              <td>{item.TypeOfSupplement}</td>
              <td>{item.RevisionNumber}</td>
              <td>{item.TimeStamp}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
          
};

export default SupplementTable;
