import React from "react";
import { MenuItem, Icon} from 'react-foundation';
class AircraftMenuComponent extends React.Component {

  render() {
    const menu = this.props.MenuContent;
    
    return (
      <>
        {menu.map(option => ( 
          
          <MenuItem><a onClick={() => this.props.onClick(option.ModelCode)}><Icon name="fi-list"/> <span>{option.ModelCode}</span></a></MenuItem>
      
        ))}
      </>
    );
  }
}
export default AircraftMenuComponent;