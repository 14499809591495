import React, {useEffect, useState} from "react";
import 'jquery';
import 'foundation-sites';
import Manuals from './components/manualsB';
import Supplements from "./components/menu/supplementsNew.component";
import './assets/css/App.css';
import './assets/css/foundation-icons/foundation-icons.css';
import Modal from 'react-foundation-modal';
import Cookies from 'js-cookie';
import axios from 'axios';
import logo from './BAE_Logo.svg';


function App() {

  const [loggedIn, setLoggedIn] = useState('true');
  const [modalIsOpen, setModalIsOpen] = useState(true);
  const overlayStyle = {'backgroundColor': 'rgba(82,82,82,0.5)'};
  const [user, setUser] = useState('');
  const [userId, setUserId] = useState('');
  const [companyId, setCompanyId] = useState('');
  const[SupplementContent, setSupplementContent] = useState([{},]);

  //for testing
  const [parentState, setParentState] = useState('Hello from the parent component!');

  useEffect(() => {
       
       
        let encodedStringAtoB = Cookies.get('user') ;  
        if(encodedStringAtoB == null) {
          setLoggedIn('false');
        }else{
          axios.post('https://tpapi.regional-services.com/x10TwT53Bm022U',{ //uatapi
            num : encodedStringAtoB
          }).then((resp)=>{
          setUser(resp.data.cn);
          //setLoggedIn('true');
          axios.post('https://tpapi.regional-services.com/zd5yH67mIl223W',{
            user: resp.data.cn
          }).then((respB)=>{
            setUserId(respB.data.userId);
            setCompanyId(respB.data.companyId);
            axios.post('https://tpapi.regional-services.com/sx67YhG42Ef37C',{
            userid: respB.data.userId,
            app : 7
            }).then((respC)=>{
              setLoggedIn(respC.data.access);
              axios.post('https://tpapi.regional-services.com/Ht67vUk3c67nPm',{
              //userId: respB.data
              companyId: respB.data.companyId //10006260 //for test purposes Wasaya
              }).then((respD) => {
                  setSupplementContent(respD.data);
              });
            });
          }); 
           
          });
        } 
  }, []);

  function showPopup(status) {
    setModalIsOpen(status);
  };

  

  

  

  if(loggedIn === 'false'){
    return (
      <div className="App">
        {/* <Header></Header> */}
        <div id="colorlib-main">
          <section className="home-bg">
          <div className="grid-x grid-padding-x small-padding-collapse">
          
          <div className="Large-12 main_container">
            <div className="grid-container fluid padding-top">
              <div className="grid-x grid-padding-x">
                <div className="small-12 medium-9 cell">
                  <h2>Access Denied</h2>
                  <p>Please login to the Regional Aircraft Portal in order to use Technical Publications.</p>
                
                </div>
              </div>
            </div>
          </div>

        </div>
          </section>
        </div>
        
        <section className="footer-container">
            <div className="grid-container">
            <div className="grid-x grid-margin-x padding-top-large">
            <div className="small-12 large-6 cell">
              <a href="https://baesystems.com" target="_blank"><img src={logo} alt="bae systems" width="200px" /></a>
              <p className="padding-top-large">&copy; 2024 BAE Systems | All Rights Reserved </p> 
            <p><a href="https://accessibility.baesystems.com/mcmw" target="_blank">Accessibility</a> | <a href="https://www.baesystems.com/en/terms-and-conditions" target="_blank">Conditions of Use</a> | <a href="https://www.baesystems.com/en/privacy" target="_blank">Privacy Policy</a> | <a href="https://www.baesystems.com/en/cookie-policy" target="_blank">Cookie Policy</a></p>
            </div>
            </div>
            </div>
            </section>
      </div>
    );
  }else if(loggedIn === 'true'){
    return (
      <div className="App">
         
        <div>
        <Manuals
        user={userId}
        ></Manuals>
        </div>

        <Modal 
                open={modalIsOpen}
                closeModal={showPopup}
                isModal={true}
                size="large"
                overlayStyle={overlayStyle}
                 >
                
                <h3>Latest Supplements</h3>
                <p>This is a list of your latest supplements that have been added / amended in the last 30 days.</p>
                <Supplements data={SupplementContent} />
                <button className="button" type="button" onClick={() => showPopup(false)} >
                    Close
                </button>

            </Modal>          
                  
        
            <section className="footer-container">
            <div className="grid-container">
            <div className="grid-x grid-margin-x padding-top-large">
            <div className="small-12 large-6 cell">
              <a href="https://baesystems.com" target="_blank"><img src={logo} alt="bae systems" width="200px" /></a>
              <p className="padding-top-large">&copy; 2024 BAE Systems | All Rights Reserved </p> 
            <p><a href="https://accessibility.baesystems.com/mcmw" target="_blank">Accessibility</a> | <a href="https://www.baesystems.com/en/terms-and-conditions" target="_blank">Conditions of Use</a> | <a href="https://www.baesystems.com/en/privacy" target="_blank">Privacy Policy</a> | <a href="https://www.baesystems.com/en/cookie-policy" target="_blank">Cookie Policy</a></p>
            </div>
            </div>
            </div>
            </section>
      </div>
    );
  }

}

export default App;
