import React from 'react';

const TPQData = () => {

  
  return (
    <div>
      <h4>TPQs - Our Customer Care Policy</h4>
<ul>
<li>Answer each query within five working days.</li>
<li>Give the Originator an estimate of when an update to their Publication/Supplement will be completed. Note: Updates to Publications will be made by Supplement.</li>
</ul>

<p>Please make sure that you:</p>
<ul>
<li>Complete all sections of the query.</li>
<li>Supply examples of queried pages.</li>
<li>Write your query in Upper & Lower case.</li>
<li>Check that the Publication/Supplement you are consulting is to the latest issue standard.</li>
<li>Use E-mail only to transmit <a href="https://portal.regional-services.com/img/TPQForm.pdf">this form</a> and any associated attachments.</li>
</ul>

<p>Please send to: <a href="mailto:rapublications@baesystems.com">rapublications@baesystems.com</a></p>
    </div>
  );
          
};

export default TPQData;
