import React, { Component } from "react";
import { Menu, Callout, Breadcrumbs, BreadcrumbItem, Icon, Label, Colors} from 'react-foundation';
import MenuLevel1Component from "../components/menu/level1.component";
import MenuLevel2Component from "../components/menu/level2.component";
import MenuLevel3Component from "../components/menu/level3.component";
import SupplementsComponent from "../components/menu/supplementsTestC.component";
import SupplementsComponentTest from "../components/menu/supplementsNew.component";
import TPQComponent from "../components/menu/TPQ.component";
import Bounce from 'react-reveal/Bounce';
import 'jquery';
import Cookies from 'js-cookie';
import axios from 'axios';
import download from 'downloadjs';
//import download from 'js-file-download';

class App extends Component{
    constructor(){
        super();

      this.state = {
        
        userId : '',
        companyId: '',

        MainMenu: 'visible',
        SubMenu: 'hide',
        ManualMenu: 'hide',
        ManualLink: 'hide',

        /* MainMenuAnimation: 'left',
        SubMenuAnimation: 'right',
        ManualMenuAnimation: 'right', */
        MainMenuBounceState: true,
        SubMenuBounceState: false,
        ManualMenuBounceState: false,
        ManualLinkBounceState: false,
        SupplementBounceState: false,
        TPQBounceState: false,

        MainMenuContent: [{},],
        SubMenuContent: [{},],
        ManualMenuContent: [{},],
        ManualLinkContent: [{},],
        SupplementACTypeMenu: [{},],

        //TPQ
        TPQAction: true,

        //Supplements
        SupplementContent: [{},],
        SupplementSidebarText: 'Supplements',
        SupplementAction: true,

        //breacrumbs
        bCrumbsInitial: 'hide',
        bCrumbsStep1: '',
        bCrumbsStep2: '',
        bCrumbsStep3: '',

        //cookie
        cookie: 'hide',
        subheader: 'Use the menu below to locate the manual you require'

        
      }


      
    }

    componentDidMount() {

      let encodedStringAtoB = Cookies.get('user') ;
      axios.post('https://tpapi.regional-services.com/x10TwT53Bm022U',{
            num : encodedStringAtoB
          }).then((resp)=>{
            //console.log(resp);
            axios.post('https://tpapi.regional-services.com/zd5yH67mIl223W',{
            user: resp.data.cn
            }).then((respB)=>{
              //console.log(respB);
              this.setState({userId : respB.data.userId});
              this.setState({companyId : respB.data.companyId});

              axios.post('https://tpapi.regional-services.com/J5Fw3EDnH73BxP/', {
                user : resp.data.cn,
                //user : 10006260, //for test purposes Wasaya
                companyId : respB.data.companyId
              }).then((respC)=>{
                this.setState({MainMenuContent: respC.data});
                /* Fetch latest supplements and if there has been a new supplement published in the last 24 hrs then show the pop up.
                Need to know userid */
                axios.post('https://tpapi.regional-services.com/Ht67vUk3c67nPm',{
                companyId: respB.data.companyId
                }).then((respD) => {
                  this.setState({SupplementContent : respD.data});
                  
                });
              });
            }); 
      });

      if(Cookies.get('msg')){
        this.setState({
          cookie : 'visible' 
        });
      }
      
    }

    changeMenu = () => {
      
        //User is on Main Menu
        this.setState({
        MainMenuBounceState: true,
        SubMenuBounceState: false,
        ManualMenuBounceState: false,
        ManualLinkBounceState: false,
        SupplementBounceState: false,
        TPQBounceState: false,
          bCrumbsInitial: 'hide',
          bCrumbsStep1Text: '', //aircraft
          bCrumbsStep1: 'hide',
          bCrumbsStep2Text: '',
          bCrumbsStep2: 'hide',
          bCrumbsStep3Text: '',
          bCrumbsStep3: 'hide',
        });
        
    }

    changeMenuA = (aircraft) => {
        //User is on Manual Type sub menu
        console.log('now on Manual Type menu and user id is '+this.state.userId);
        axios.post('https://tpapi.regional-services.com/yV635Knb05R3eY', {
          aircraftModel : aircraft,
          user : this.state.userId,
          companyId : this.state.companyId
          //user : 10006260 //for test purposes Wasaya
        }).then((resp)=>{
          this.setState({
            SubMenuContent: resp.data,
            MainMenuBounceState: false,
            SubMenuBounceState: true,
            ManualMenuBounceState: false,
            ManualLinkBounceState: false,
            SupplementBounceState: false,
            TPQBounceState: false,

            bCrumbsInitial: 'visible',
            bCrumbsStep1Text: aircraft,
            bCrumbsStep1: 'visible',
            bCrumbsStep2Text: '',
            bCrumbsStep2: 'hide',
            bCrumbsStep3Text: '',
            bCrumbsStep3: 'hide',

            });
        });
    }
    
    changeMenuB = (aircraft, type) => {
        //User is on Select Manual Menu
        console.log('now on Select Manual Menu');
        axios.post('https://tpapi.regional-services.com/Hn62Wrd8Hbv90e', {
          aircraftModel : aircraft,
          manualType : type,
          user : this.state.userId,
          companyId : this.state.companyId
          //user : 10006260 //for test purposes Wasaya
        }).then((resp)=>{
          
          this.setState({
            ManualMenuContent: resp.data,
            MainMenuBounceState: false,
            SubMenuBounceState: false,
            ManualMenuBounceState: true,
            ManualLinkBounceState: false,
            SupplementBounceState: false,
            TPQBounceState: false,

            bCrumbsInitial: 'visible',
            bCrumbsStep2Text: type,
            bCrumbsStep2: 'visible',
            bCrumbsStep3Text: '',
            bCrumbsStep3: 'hide',
          });
        });
    }

    

      changeMenuC = (ManualID) => {
        //User is on Download Manual 
      console.log('now on Download Manual Menu');
      
      axios.post('https://tpapi.regional-services.com/pdf', {
        manualId : ManualID,
        //user : this.state.userId
        user : 10006260, //for test purposes Wasaya
        app : 7,
        responseType: 'blob' 
      }).then((res)=>{
        download(res.data, 'manual.pdf');
        
      }

      );
      }

      TPQ = () => {
        if(this.state.TPQAction == true){
          this.setState({
            TPQBounceState : true,
            SupplementBounceState : false,
            MainMenuBounceState: false,
            SubMenuBounceState: false,
            ManualMenuBounceState: false,
            ManualLinkBounceState: false,
            bCrumbsInitial: 'hide'
          })
        }else{
          this.setState({
            TPQBounceState : false,
            SupplementBounceState : false,
            MainMenuBounceState: true,
            SubMenuBounceState: false,
            ManualMenuBounceState: false,
            ManualLinkBounceState: false,
            bCrumbsInitial: 'hide'
          })
        }
      }

      Supplements = () => {
        //`Fetch AC Types for Supplements Filter
        axios.post('https://tpapi.regional-services.com/Ht67vUk3c57nPm', {
          companyId : this.state.companyId
        }).then((res) => {
          //console.log('supplement filters are: '+ res);
          this.setState({SupplementACTypeMenu: res.data});
        });
        //console.log('supplement ac types are: ' + this.state.SupplementACTypeMenu);
        if(this.state.SupplementAction == true){
          this.setState({
            
            SupplementBounceState : true,
            TPQBounceState: false,
            MainMenuBounceState: false,
            SubMenuBounceState: false,
            ManualMenuBounceState: false,
            ManualLinkBounceState: false,
            bCrumbsInitial: 'hide',

            SupplementSidebarText: 'Back to Manuals',
            SupplementAction: !this.state.SupplementAction,
            subheader: 'Supplements are located in the below table. Use the filters to narrow results'
          });
        }else{
          this.setState({
            
            SupplementBounceState : false,
            TPQBounceState: false,
            MainMenuBounceState: true,
            SubMenuBounceState: false,
            ManualMenuBounceState: false,
            ManualLinkBounceState: false,
            bCrumbsInitial: 'hide',

            SupplementSidebarText: 'Supplements',
            SupplementAction: !this.state.SupplementAction,
            subheader: 'Use the menu below to locate the manual you require'
          });
        }
      }

        downloadSupplement = (id) => {
          //console.log('sup id is:'+id);
          axios.post('https://tpapi.regional-services.com/downloadSupplement', {
            supplementId : id,
            //user : this.state.userId
            user : 10006260, //for test purposes Wasaya
            app : 7,
            responseType: 'stream',
            responseEncoding: 'utf8'
          }).then((res)=>{
            download(res.data, 'supplement.pdf', 'application/pdf');
            //console.log(res.data);
            
          });
        }
        
      updateSupplements = () => {

      }
    
      backToPortal = () => {
        window.location.href = "https://portal.regional-services.com/admin";
      }

render(){
  //console.log('user is '+this.state.userId);
    return(
        <div >
          <div id="colorlib-main">
          <section className="home-bg">
          <div className="grid-x grid-padding-x small-padding-collapse">
          <div className="Large-1 medium-2 small-12 cell sidebar full_height">
          <ul class="list-group">
          <li class="list-group-item" onClick={() => this.Supplements(this.state.SupplementAction)}><Icon name="fi-alert"/> {this.state.SupplementSidebarText}</li>
          <li class="list-group-item" onClick={() => this.TPQ(this.state.TPQAction)}><Icon name="fi-comment"/> TPQ</li>
          <li class="list-group-item" onClick={() => this.backToPortal()}><Icon name="fi-home"/> Back to Portal</li>
          </ul>
          </div>
          <div className="Large-11 medium-10 small-12 main_container">
            <div className="grid-container fluid padding-top">
              <div className="grid-x grid-padding-x">
                <div className="small-12 medium-9 cell">
                  <h1 class="topbar-responsive-logo"><strong><span>Technical</span> Publications</strong></h1>
                  <h3>Welcome to BAE Systems Regional Aircraft Technical Publications Service</h3>
                  <p>{this.state.subheader}</p>
                  <Label color={Colors.WARNING} className={this.state.cookie}>Cookie</Label>
                  </div></div></div> 
                  <div className="grid-container fluid padding-top">
                  <div className="grid-x grid-padding-x">

        <div className="breadcrumbs-example">
        <nav aria-label="You are here:" role="navigation" className={this.state.bCrumbsInitial}>
        <Breadcrumbs>
        <BreadcrumbItem><a onClick={() => this.changeMenu()}>{this.state.bCrumbsStep1Text}</a></BreadcrumbItem>
        <BreadcrumbItem className={this.state.bCrumbsStep2}><a onClick={() => this.changeMenuA(this.state.bCrumbsStep1Text)}>{this.state.bCrumbsStep2Text}</a></BreadcrumbItem>
        <BreadcrumbItem className={this.state.bCrumbsStep3}><a onClick={() => this.changeMenuB(this.state.bCrumbsStep1Text, this.state.bCrumbsStep2Text)}>{this.state.bCrumbsStep3Text}</a></BreadcrumbItem>
        </Breadcrumbs>
        </nav>
        </div>

        </div></div>

        <div className="grid-container fluid padding-top">
        <div className="grid-x grid-padding-x">
        <div className="small-12 cell">
    
        <Bounce right opposite collapse when={this.state.MainMenuBounceState}>
        <Callout>
        <Menu isVertical >
        <h5>Select Aircraft Model</h5>
        <MenuLevel1Component 
        MenuContent={this.state.MainMenuContent} 
        onClick={this.changeMenuA}
        />
        </Menu>
        </Callout>
        </Bounce>

        {/* Select Manual Type Menu , hidden */}
        <Bounce right opposite collapse when={this.state.SubMenuBounceState}>
        <Callout>
        <Menu isVertical >
        <h5>Select Manual Type</h5>
        <MenuLevel2Component 
          MenuContent={this.state.SubMenuContent} 
          onClick={this.changeMenuB}
          />
        </Menu>
        </Callout>
        </Bounce>

        {/* Select Manual */}
        <Bounce right opposite collapse when={this.state.ManualMenuBounceState}>
        <Callout>
        <Menu isVertical>
        <h5>Select Manual</h5>
        <MenuLevel3Component 
          MenuContent={this.state.ManualMenuContent} 
          onClick={this.changeMenuC}
          />
        </Menu>
        </Callout>
        </Bounce>

        {/* Supplements modal */}
        <Bounce right opposite collapse when={this.state.SupplementBounceState}>
        <Callout>
        <SupplementsComponentTest
        data={this.state.SupplementContent}
        />
        </Callout>
        </Bounce>

        <Bounce right opposite collapse when={this.state.TPQBounceState}>
        <Callout>
        <TPQComponent/>
        </Callout>
        </Bounce>

                </div>
                </div>
              </div>
            </div> 
          </div>

          </section>
        </div>
        </div>
    );
}

}

export default App;